<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeContacts.title') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <!-- Local contacts -->
      <ion-card v-for="contact in contacts" :key="contact.id">
        <ion-card-header>
          <ion-card-title>
            {{ contact.lastname }}
            {{ contact.firstname }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p v-if="contact.sector">
            {{ $t('labels.sector') }}
            {{ contact.sector.name }}
          </p>
          <p v-if="contact.email">
            {{ $t('labels.email') }}
            <a :href="`mail:${contact.email}`">{{ contact.email }}</a>
          </p>
          <p v-if="contact.phone">
            {{ $t('labels.phone') }}
            <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
          </p>
        </ion-card-content>
      </ion-card>

      <ODNNoContent v-if="contacts.length === 0" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonBackButton,
} from '@ionic/vue';
import { ellipseOutline, add } from 'ionicons/icons';

import ODNNoContent from '@c/odn-no-content.vue';

export default {
  name: 'OfflineStoreTickets',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,

    IonBackButton,
    ODNNoContent,
  },
  data() {
    return {
      contacts: [],
      contactList: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      icons: {
        ellipseOutline,
        add,
      },
    };
  },
  computed: {
    ...mapState('stores', {
      store: 'selectedStore',
    }),
  },
  watch: {
    store: {
      handler() {
        this.contactList = this.store.contacts;
        this.parseData(true);
      },
    },
  },
  async ionViewDidEnter() {
    if (this.store) {
      this.contactList = this.store.contacts;
    }
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.contacts = [];
    this.contactList = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
  },
  methods: {
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.contacts = [];
      }

      this.contacts = this.contacts.concat(
        this.contactList
          .filter((c) => {
            const search = this.searchText.toLowerCase();
            return (
              c.lastname.toLowerCase().indexOf(search) > -1 ||
              c.firstname.toLowerCase().indexOf(search) > -1 ||
              c.email.toLowerCase().indexOf(search) > -1 ||
              (c.sector && c.sector.name.toLowerCase().indexOf(search) > -1)
            );
          })
          .slice(this.page * this.limit, this.page * this.limit + this.limit)
      );

      if (this.contacts.length < this.contactList.length) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
  },
};
</script>
